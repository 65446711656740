.button-bootstrap {
  background: #ffffff !important;
  border: 1px solid #f2f2f5 !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  height: 58px !important;
}

.btn {
  background: #ffffff;
  border: 1px solid #f2f2f5;
  box-shadow: none;
}

.btn:hover {
  background: #f2f2f5 !important;
  border: 1px solid #f2f2f5 !important;
  box-shadow: none !important;
}

.container-button-content {
  display: flex;
  margin-left: 7px;
  margin-right: 7px;
  align-items: center;
  width: 100%;
}

.content-button-img-delivery {
  width: 24px;
  height: 24px;
}

.content-button-img-authImg {
  width: 15px;
  height: 21px;
}

.content-button-img-basket {
  width: 24px;
  height: 24px;
}

.content-button-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5032c8;
  margin: 0;
  margin-left: 13px;
}

._delivery {
  max-width: 200px;
}

._authImg {
  max-width: 127px;
}

._basket {
  max-width: 126px;
}

.__auth-button {
  background: #5032c8 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16) !important;
  border-radius: 22px !important;
  max-width: 224px !important;
  width: 100% !important;


  &:disabled {
    pointer-events: none;
    background-color: #F2F2F2 !important;
    max-width: unset !important;
    width: auto !important;

    .__auth-button-text {
      color: #C3C3C7 !important;

    }
  }
}

.outline-btn {
  background: #FFFFFF !important;
  border: 1px solid #E6E6EB !important;
  border-radius: 22px !important;
  max-width: 224px !important;
  width: 100% !important;
  box-shadow: none !important;
}

.__auth-button:hover {
  background: #3f24ac !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16) !important;
}

.outline-btn:hover {
  background: #f2f2f5 !important;
}

.__auth-button-text {
  color: #ffffff !important;
  margin-left: 0px !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.outline-btn__text {
  color: #333333 !important;
  font-family: Gerbera;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.__auth-button-content {
  margin: 0 !important;
  justify-content: center !important;
}

.__auth-button-content:hover {
  margin: 0 !important;
  justify-content: center !important;
}

@media screen and (max-width: 430px) {
  .__auth-button {
    max-width: unset !important;
  }
}
